import { isEqual, isObject, transform } from 'lodash-es';
import { BPMMxGraph } from '../mxgraph/bpmgraph';
import { BPMMxGraphModel } from '../mxgraph/BPMMxGraphModel.class';
import { MxCell, MxCellState, MxGeometry, MxUtils } from '../mxgraph/mxgraph';

export const changes = (object: {}, base: {}): any => {
    return transform(object, (result: {}, value, key) => {
        if (!isEqual(value, base?.[key])) {
            result[key] = isObject(value) && isObject(base[key]) ? changes(value, base[key]) : value;
        }
    });
};

export const getIntersectCell = (graph: BPMMxGraph, movedCell: MxCell, checkedCells: MxCell[]): MxCell | null => {
    const movedObjState: MxCellState | undefined = graph.getView().getState(movedCell);
    const movedCellGeometry: MxGeometry = movedObjState?.cellBounds || movedCell.geometry;
    let intersectObj: MxCell | null = null;

    checkedCells.forEach((cell) => {
        if (cell.id === movedCell.id) {
            return;
        }
        const cellState = graph.getView().getState(cell);
        if (MxUtils.intersects(cellState.cellBounds, movedCellGeometry)) {
            intersectObj = cell;
        }
    });

    return intersectObj;
};

/**
 * Функция определяет на сколько процентов объект с меньшей площадью из "secondRect" и "rect" перекрывает другой объект
 *
 * @return {number} - процент пересечения.
 */
export const getIntersectPercentBySmallerRect = (rect: MxGeometry, secondRect: MxGeometry): number => {
    const a = {
        x1: rect.x,
        y1: rect.y,
        x2: rect.x + rect.width,
        y2: rect.y + rect.height,
    };

    const b = {
        x1: secondRect.x,
        y1: secondRect.y,
        x2: secondRect.x + secondRect.width,
        y2: secondRect.y + secondRect.height,
    };

    // отрезки пересекаемых объектов
    const intersectingXSegment = Math.min(a.x2, b.x2) - Math.max(a.x1, b.x1);
    const intersectingYSegment = Math.min(a.y2, b.y2) - Math.max(a.y1, b.y1);

    // не учитывать отрицательные числа
    const intersectingXSegmentOrZero = Math.max(0, intersectingXSegment);
    const intersectingYSegmentOrZero = Math.max(0, intersectingYSegment);

    // площадь общей области пересечения
    const intersectionArea = intersectingXSegmentOrZero * intersectingYSegmentOrZero;

    // площадь rect
    const rectArea = rect.width * rect.height;
    // площадь secondRect
    const secondObjectArea = secondRect.width * secondRect.height;

    const smallerObjectArea = rectArea < secondObjectArea ? rectArea : secondObjectArea;
    const onePercentOfSmallerbjectArea = smallerObjectArea / 100;

    // процент перекрытия
    const intersectPercentBySmallerRect = intersectionArea / onePercentOfSmallerbjectArea;

    return Math.round(intersectPercentBySmallerRect);
};

export const getHalfIntersectingCells = (underlyingCell: MxCell, allGraphCells: MxCell[]): MxCell[] => {
    const { mxObjectId: underlyingCellId, parent: underlyingCellParent } = underlyingCell;

    const allCellsExceptUnderlyingCell = allGraphCells.filter((cell) => {
        return cell.mxObjectId !== underlyingCellId && underlyingCellParent?.id === cell.parent?.id;
    });

    const underlyingCellGeo = underlyingCell.getGeometry();

    return allCellsExceptUnderlyingCell.filter((cell) => {
        const { x, y, width: w, height: h } = cell.getGeometry();
        const rect = new MxGeometry(x, y, w, h);
        const intersectPercent = getIntersectPercentBySmallerRect(underlyingCellGeo, rect);
        const underlyingCellArea = underlyingCellGeo.width * underlyingCellGeo.height;
        const rectArea = rect.width * rect.height;

        return underlyingCellArea >= rectArea && intersectPercent >= 50;
    });
};

export const getCellGeometry = (cell: MxCell, model: BPMMxGraphModel): MxGeometry => {
    const currentCellGeometry = cell.getGeometry();

    if (!model.isRoot(cell?.parent?.parent)) {
        const parentCellGeometry = getCellGeometry(cell?.parent, model);

        return {
            ...currentCellGeometry,
            x: currentCellGeometry.x + parentCellGeometry.x,
            y: currentCellGeometry.y + parentCellGeometry.y,
        } as MxGeometry;
    }

    return currentCellGeometry;
};

export const getIntersection = (
    graph: BPMMxGraph,
    movedCell: MxCell,
    intersectionMap: Record<string, string | null>,
    intersectableCellTypes: string[] | undefined,
): MxCell | null | undefined => {
    const allGraphCells = Object.values<MxCell>(graph.model.cells);
    const allGraphIntersectableCells = allGraphCells.filter((cell) =>
        intersectableCellTypes?.includes(cell.value?.type),
    );
    const intersectedCell = getIntersectCell(graph, movedCell, allGraphIntersectableCells);

    if (intersectedCell) {
        const movedCellGeometry = getCellGeometry(movedCell, graph.model);
        const intersectedCellGeometry = getCellGeometry(intersectedCell, graph.model);
        const intersectPercent = getIntersectPercentBySmallerRect(movedCellGeometry, intersectedCellGeometry);
        const sameCellIntercepted =
            intersectionMap[movedCell.id] === intersectedCell.id ||
            intersectionMap[intersectedCell.id] === movedCell.id;
        if (!sameCellIntercepted && intersectPercent >= 5) {
            return intersectedCell;
        }
    } else {
        return null;
    }

    return undefined;
};
